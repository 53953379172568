@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");
:root {
  --primary: #8e8ce6;
  --primary-hover: #cd7d7c;
  --secondary: #efd0f8;
  --secondary-hover: #b8a1d9;
  --aura: #9265ca80;
  --background: #3c3353;
  --nav-background: #141318;
  --nav-item-bg-hover: #79797920;
  --navbar-height: 100vh;
  --backgroundLogin: #382f2f;
  --primary1: #dd946f;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: Arial, Helvetica, sans-serif;
}

.container-sidebar {
  display: flex;
}

.topMargin {
  font-size: 18px;
  margin-top: 40px;
}

.sidebar {
  height: 100vh;
  overflow-y: auto; /* Allow sidebar content to scroll if it exceeds viewport height */
  color: #f3ecec;
  width: 150px; /* Fixed width */
  transition: all 0.5s;
  text-align: center;
  background: var(--nav-background);
  height: var(--navbar-height);
  flex-direction: row;
  border-right: 5px solid var(--aura);
}

.main-content {
  height: 100vh;
  overflow-y: auto; /* Allow sidebar content to scroll if it exceeds viewport height */
  /* Other sidebar styles */
  width: 100%;
  padding-left: 10px;
  margin-right: 10px;
  font-size: 13px;
}

table {
  width: 100%;
  table-layout: fixed;
  background-color: hsl(0, 0%, 95%);
  white-space: pre-wrap;
  word-wrap: break-word;
}

.link-active {
  display: flex;
  color: #f8f0f0;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}

.link-active:hover {
  background: rgb(239, 244, 247);
  color: #000;
}

.active {
  background: rgb(239, 242, 243);
  color: #000;
}

/* Active NavLink */
.link-active.active {
  background: rgb(238, 243, 247);
  color: black; /* Text color for active link */
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background: rgba(216, 73, 73, 0.2); */
  width: 100%;
  font-weight: 200;
  font-size: 12px;
}

.pagination-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  /* padding: 8px 14px; */
  height: 35px;
  width: 35px;
  text-decoration: none;
  transition: background-color 0.2s;
  border: 1px solid #ddd;
  cursor: pointer;
}

.pagination-container button.active {
  background-color: #007bff;
  color: white;
  border: 1px solid #7cbddb;
}

.pagination-container button.disabled {
  opacity: 0.2;
}

.pagination-container button:hover:not(.active) {
  background-color: rgb(238, 238, 238);
}

.my-form {
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 1rem;
  background-color: var(--background);
  border: 1px solid var(--primary);
  border-radius: 1rem;
  box-shadow: 0 30px 60px var(--aura);
  min-width: 17.5rem;
  max-width: 28.75rem;
  width: 100%;
  padding: 2rem;
}

.bg-form {
  background-color: var(--backgroundLogin);
  border: 1px solid var(--primary);
}

.gradient-p-w-p {
  height: 200px;
  background-image: linear-gradient(pink, white, pink);
}

.font-10 {
  font-size: 10px;
}
.font-12 {
  font-size: 12px;
}
.font-11 {
  font-size: 11px;
}
.font-13 {
  font-size: 13px;
}
